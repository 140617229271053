<template>
  <v-list dense shaped class="pl-3">
    <h4 class="left-nav section-title pl-3 mb-3">
      <v-icon size="20">mdi-clipboard-text</v-icon>
      <span class="pl-2">{{ title }}</span>
    </h4>
    <v-list-group :value="true" v-if="navigator.examinations.length">
      <template v-slot:activator>
        <v-list-item-title>{{ $t("examination.title") }}</v-list-item-title>
      </template>
      <v-list-item
        v-for="(order, ind) in navigator.examinations"
        :key="order.id"
        :to="order.path"
        link
        active-class="active-class"
        exact
      >
        <template>
          <v-list-item-icon>
            <v-icon v-if="order.statusID == 3" class="green--text">
              mdi-checkbox-marked-circle
            </v-icon>
            <v-icon v-else> mdi-checkbox-blank-circle-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ ind + 1 }}. {{ order.name }}</v-list-item-title>
        </template>
      </v-list-item>
    </v-list-group>
    <v-list-item
      v-for="nav in navigator.navigations"
      :key="nav.path"
      :to="nav.path"
      link
      active-class="active-class"
    >
      <v-list-item-title>{{ nav.name }}</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    navigator: {
      type: Object,
      default: function () {
        return {
          examinations: [],
          navigations: [],
        };
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.active-class {
  background: white;
}
.left-nav.section-title {
  text-transform: uppercase;
}
</style>
