<template>
  <v-container class="pa-0 ma-0 pt-5 pb-2 service-information">
    <v-container class="pa-0 ma-0 fw h-4">
      <p class="fl lh-4 mr-3 row-title">Package:</p>
      <v-select
        v-model="itemType"
        :items="itemTypes"
        filled
        dense
        full-width
        label="Item Type"
        append-icon="mdi-book-outline"
        class="fl mr-3"
        style="width: 200px"
      ></v-select>
      <v-autocomplete
        v-model="itemID"
        :items="items"
        filled
        dense
        full-width
        :label="'Choose ' + itemTypes.find((i) => i.value == itemType).text"
        append-icon="mdi-package-variant-closed"
        class="fl mr-3"
        style="width: 320px"
      ></v-autocomplete>
      <v-btn
        :loading="isAddingMoreItem"
        @click="
          isAddingMoreItem = true;
          addIntoTreeView(itemID, itemType);
        "
        elevation="2"
        class="h-4 lh-4"
      >
        {{ $t("common.add") }}</v-btn
      >
      <v-select
        v-show="false"
        v-model="promotionID"
        :items="filteredPromotions"
        filled
        dense
        full-width
        class="fr mr-3"
        label="Promotion Code"
        style="width: 250px"
        append-icon="mdi-code-tags"
      ></v-select>
      <p v-show="false" class="fr lh-4 mr-3 row-title">
        {{ $t("package.promotion") }}:
      </p>
    </v-container>
    <v-container class="pa-0 ma-0 fw mt-3">
      <p class="fl lh-4 row-text" style="width: calc(100% - 0px)">
        <v-toolbar dense dark flat class="primary">
          <p
            class="fl ma-0 pl-4"
            :style="{ width: allowFreeSelection ? '435px' : '495px' }"
          >
            {{ $t("package.packageName") }}
          </p>
          <p v-if="allowFreeSelection" class="fl ma-0 tc" style="width: 70px">
            {{ $t("package.free") }}
          </p>
          <p class="fl ma-0 tc" style="width: 150px">
            {{ $t("package.type") }}
          </p>
          <p class="fl ma-0 tc" style="width: 180px">
            {{ $t("package.doctor") }}
          </p>
          <p class="fl ma-0 tc" style="width: 160px">
            {{ $t("package.room") }}
          </p>
          <p class="fl ma-0 tc" style="width: 140px">
            {{ $t("package.originalPrice") }}
          </p>
          <p class="fl ma-0 tc" style="width: 200px">
            {{ $t("package.discountPrice") }}
          </p>
        </v-toolbar>
        <v-skeleton-loader
          height="200px"
          :loading="isLoadingServices"
          type="table-heading, list-item, list-item, list-item"
        >
          <v-treeview
            v-if="treeItems.length"
            open-all
            open-on-click
            selectable
            dense
            return-object
            selected-color="primary"
            v-model="selections"
            :items="treeItems"
            :open.sync="opens"
          >
            <template v-slot:label="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:append="{ item }">
              <p
                class="fr ma-0 h-4 lh-4 tc"
                :class="{ success: item.isFree || item.isPromoted }"
                style="width: 192px"
              >
                {{
                  item.isFree
                    ? "FREE"
                    : item.isParent
                    ? item.discounted
                    : "included"
                }}
              </p>
              <p class="fr ma-0 h-4 lh-4 tc" style="width: 180px">
                {{ item.isParent ? item.price : "included" }}
              </p>
              <p class="fr ma-0 h-4 lh-4 tc" style="width: 120px">
                {{ item.room }}
              </p>
              <p class="fr ma-0 h-4 lh-4 tc" style="width: 210px">
                <span v-if="!item.doctorOptions.length">{{ item.doctor }}</span>
                <v-select
                  v-else
                  :items="item.doctorOptions"
                  v-model="item.doctorID"
                  @change="handleChangeOrderItemDoctor($event, item)"
                  dense
                  outlined
                  style="width: 90%; margin-left: 5%"
                >
                  <template v-slot:selection="{ item }">
                    <span class="d-flex justify-center" style="width: 100%">
                      {{ item.text }}
                    </span>
                  </template>
                </v-select>
              </p>
              <p class="fr ma-0 h-4 lh-4 tc" style="width: 120px">
                {{ item.typeName }}
              </p>
              <p
                v-if="allowFreeSelection && item.isParent"
                class="fr ma-0 h-4 lh-4 tc"
                style="width: 60px"
              >
                <v-checkbox
                  color="success"
                  style="margin-top: 5px"
                  v-model="item.isFree"
                  @change="displayPriceStrings"
                ></v-checkbox>
              </p> </template
          ></v-treeview>
          <span class="no-data-text" v-else>
            {{ $t("package.addTitle") }}
          </span>
        </v-skeleton-loader>
        <v-toolbar dense flat color="grey lighten-2">
          <p class="fl ma-0 tr bold" style="width: 1055px">
            {{ $t("package.total") }}
          </p>
          <p class="fl ma-0 tr bold" style="width: 220px">
            {{ totalPriceString }}
          </p>
        </v-toolbar>
        <v-toolbar dense flat color="grey lighten-2">
          <p class="fl ma-0 tr bold" style="width: 1055px">
            {{ $t("package.discount") }}
          </p>
          <p class="fl ma-0 tr bold" style="width: 220px">
            - {{ totalDiscountPriceString }}
          </p>
        </v-toolbar>
        <v-toolbar dense dark flat class="primary">
          <p class="fl ma-0 tr bold" style="width: 1055px">
            {{ $t("package.finalPrice") }}
          </p>
          <p class="fl ma-0 tr bold" style="width: 220px">
            {{ finalPriceString }}
          </p>
        </v-toolbar>
      </p>
    </v-container>
  </v-container>
</template>

<script>
import PromotionService from "@/services/promotion";
import AxPxService from "@/services/axpx";
import BrandService from "@/services/brand";
import ServiceService from "@/services/service";
import { AuditType } from "@/plugins/constant";
import { convertPriceString, awaitAll, makeUuid } from "@/plugins/helper";

export default {
  components: {},
  watch: {
    // When itemType change
    // Render list OrderItem Options
    itemType: {
      handler() {
        this.renderListOrderItemOptions();
      },
    },
    // When selections of OrderItem change
    // compare to get the changed element
    selections: {
      handler(newVal, oldVal) {
        // If added then nothing happens
        if (newVal.length >= oldVal.length) return;
        // If removed, find the removed item
        var removedItems = oldVal.filter(
          ({ id: id1 }) => !newVal.some(({ id: id2 }) => id2 === id1)
        );
        this.handleRemovedOrderItems(removedItems);
      },
    },
    // When PromotionID change
    // Update description text
    // Reload all prices
    // Re-calculate total prices
    promotionID: {
      handler() {
        this.reloadPrice();
        this.displayPriceStrings();
      },
    },
  },
  async created() {
    this.renderListPromotions();
    this.renderListOrderItemOptions();
  },
  data: () => ({
    // always allow EMR to select Free
    allowFreeSelection: true,
    // House all promotions
    promotions: [],
    // House only promotions for selected services
    filteredPromotions: [],
    promotionID: null,
    isLoadingServices: false,
    isAddingMoreItem: false,
    promotionDescriptionText: "",
    items: [],
    itemID: null,
    itemType: AuditType.Ax,
    itemTypes: [
      { value: 1, text: "Assessment" },
      { value: 2, text: "Procedure" },
      { value: 3, text: "Service" },
    ],
    treeItems: [],
    selections: [],
    opens: [],
    totalPriceString: "",
    totalDiscountPriceString: "",
    finalPriceString: "",
    rooms: [],
  }),
  computed: {},
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    resetForm() {
      this.treeItems = [];
      this.selections = [];
    },
    // Function that reload all pricing
    // basing on selected promotionID
    reloadPrice() {
      this.treeItems.forEach((i) => {
        var validPromotionID = i.promotionIDs.find(
          (id) => id == this.promotionID
        );
        if (validPromotionID) {
          var discounted = this.calculateDiscountPrice(
            i.priceValue,
            validPromotionID
          );
          i.discountedValue = discounted;
          i.discounted = convertPriceString(discounted);
          i.isPromoted = true;
        } else {
          i.discountedValue = i.priceValue;
          i.discounted = convertPriceString(i.priceValue);
          i.isPromoted = false;
        }
      });
      this.$forceUpdate();
      this.opens = this.treeItems;
    },
    // Function that add an OrderItem into TreeView
    // What it does:
    // 1. Get list details of one single item
    // 2. Convert to treeview item
    // 3. Add into TreeView
    // 4. Add into selections
    async addIntoTreeView(targetID, typeID) {
      var items = await this.getOrderItemDetails(targetID, typeID);
      items = items.map((i) => this.convertIntoTreeItem(i));
      // validate if item is violated
      if (
        // If any item existed that
        items.find(
          (i) =>
            // is service but with no price or...
            (i.type == AuditType.Service && !i.priceValue) ||
            // is not service but with no room and doctor
            (i.type != AuditType.Service && (!i.roomID || !i.doctorID))
        )
      ) {
        this.showError(
          `This item cannot be addded in this clinic! Please select another one`
        );
        this.isLoadingServices = false;
        this.isAddingMoreItem = false;
        return;
      }
      this.isAddingMoreItem = false;
      // find service item
      var service = items.find((i) => i.type == AuditType.Service);
      // filter axpx items
      var axpxs = items.filter((i) => i.type != AuditType.Service);
      // add serviceID into each axpx item
      if (service && axpxs.length) {
        axpxs.forEach((i) => (i.serviceID = service.itemID));
      }
      // if service without any axpx
      if (service && !axpxs.length) {
        this.showError(
          `This service does not contain any items. Please select another one!`
        );
        this.isLoadingServices = false;
        this.isAddingMoreItem = false;
        return;
      }
      // map selections => only add axpxs
      // because service is just a group
      // real items are axpxs
      this.selections = this.selections.concat(axpxs);
      // map treeItems
      if (service) {
        service.children = axpxs;
        service.isParent = true;
        service.serviceID = service.itemID;
        this.treeItems.push(service);
      } else {
        axpxs.forEach((i) => (i.isParent = true));
        this.treeItems = this.treeItems.concat(axpxs);
      }
      // map open items
      this.opens = this.treeItems;
      this.displayPriceStrings();
      this.renderListFilteredPromotions();
      this.$emit("onTreeItemsChanged", this.treeItems);
    },
    handleChangeOrderItemDoctor(doctorID, orderItem) {
      var roomID = orderItem.rooms.find((r) => r.doctorID == doctorID).roomID;
      var roomName = orderItem.rooms.find((r) => r.roomID == roomID).roomName;
      // then find the order item inside treeItems and update it!
      for (var item of this.treeItems) {
        // if item is Service => children [] has length
        if (item.children && item.children.length) {
          for (var child of item.children) {
            if (child.id == orderItem.id) {
              child.roomID = roomID;
              child.room = roomName;
              return;
            }
          }
        } else {
          if (item.id == orderItem.id) {
            item.roomID = roomID;
            item.room = roomName;
            return;
          }
        }
      }
      this.$forceUpdate();
    },
    // Function that convert OrderItem into TreeItem
    convertIntoTreeItem(orderItem) {
      // transform doctors
      var doctorOptions = [];
      var { rooms } = orderItem;
      if (rooms && rooms.length > 1) {
        doctorOptions = rooms.map((r) => ({
          value: r.doctorID,
          text: r.doctorName,
        }));
      }
      var room = (orderItem.rooms && orderItem.rooms[0]) || {};
      var { id, type, name, price, promotionIDs } = orderItem;
      var itemID = id;
      var validPromotionID = promotionIDs.find((id) => id == this.promotionID);
      var discounted = this.calculateDiscountPrice(price, validPromotionID);
      id = `${makeUuid(5)}`;
      return {
        id,
        itemID,
        type,
        name,
        rooms,
        doctorOptions,
        typeName: this.itemTypes.find((i) => i.value == type).text,
        priceValue: price,
        discountedValue: discounted,
        price: convertPriceString(price),
        discounted: convertPriceString(discounted),
        room: room.roomName || "",
        roomID: room.roomID || "",
        doctor: room.doctorName || "",
        doctorID: room.doctorID || "",
        children: [],
        promotionIDs,
      };
    },
    // Function that get all children AxPx of one ServiceID
    async getListOrderItemsByServiceID(serviceID) {
      const result = await ServiceService.searchChildAxPx(serviceID);
      if (result.error) {
        this.showError(
          "Can not get list service children items. Please try again later."
        );
        return;
      }
      return result.axPxs;
    },
    // Function that get all details of a single OrderItem
    // If OrderItem is Ax, Px => return [] with single Detail
    // If OrderItem is Service => return [] of each Details
    async getOrderItemDetails(targetID, typeID) {
      this.isLoadingServices = true;
      if (!targetID || !typeID) return;
      // Get list all item order details
      var itemOrders = [];
      if (typeID == AuditType.Service) {
        // 1. Get list all item order of this service
        itemOrders = await this.getListOrderItemsByServiceID(targetID);
        // 2. Add the service itself into the list
        itemOrders.unshift({
          id: targetID,
          typeID: AuditType.Service,
        });
      } else {
        itemOrders.push({
          id: targetID,
          typeID,
        });
      }
      // 3. Promise all to get all item orders
      var promises = [];
      itemOrders.forEach((item) => {
        promises.push(this.getOrderItemDetail(item.id, item.typeID));
      });
      var result = await awaitAll(promises).catch(() => {
        this.showError(
          "An error occured while get list item order details. Please try again later"
        );
      });
      this.isLoadingServices = false;
      // only filter item detail = service
      // if not, filter item that have rooms != []
      return result.filter(
        (i) => i.type == AuditType.Service || i.rooms.length
      );
    },
    // Function that return Price, Room, Doctor, PromotionIDs
    // for each OrderItem
    async getOrderItemDetail(targetID, typeID) {
      const result = await AxPxService.getOrderItemDetail(targetID, typeID);
      if (result.error) {
        this.showError(
          "Can not get item order detail. Please try again later."
        );
        return;
      }
      return result;
    },
    // Function that render all OrderItem options
    async renderListOrderItemOptions() {
      let result;
      if (this.itemType == 3) {
        result = await ServiceService.search("", 1, 1, 100);
      } else {
        result = await AxPxService.search("", "", this.itemType, 1, 1, 1000);
      }
      if (result.error) {
        this.showError("Can not get list services. Please try again later.");
        return;
      }
      this.items = result.items.map((i) => ({
        ...i,
        value: i.id,
        text: i.description,
      }));
      // auto map value đầu tiên
      this.itemID = this.items[0] && this.items[0].value;
    },
    // Function that render all promotion options
    async renderListPromotions() {
      const result = await PromotionService.search("", "", "", 1, 100);
      if (result.error) {
        this.showError("Can not get list promotions. Please try again later.");
        return;
      }
      this.promotions = result.items.map((i) => {
        var description = "";
        if (!i || !i.amount) {
          description = "";
        } else {
          description = `${i.description} (- ${
            i.isPercentage ? i.amount + "% Price" : convertPriceString(i.amount)
          })`;
        }
        return {
          value: i.id,
          text: `${i.promotionCode} - ${description}`,
          ...i,
        };
      });
      this.promotions.unshift({
        value: null,
        text: "No promotion",
        isPercentage: false,
        amount: 0,
      });
    },
    // Function that get discount price
    // given original price of order item
    // and a valid promotionID for this orderItem
    calculateDiscountPrice(price, promotionID) {
      let promotion = this.promotions.find((p) => p.id == promotionID);
      if (!price) {
        return "";
      }
      if (promotion) {
        const discount = promotion.isPercentage
          ? (promotion.amount * price) / 100
          : promotion.amount;
        price -= discount;
      }
      return price;
    },
    // Function that calculate and display priceString
    displayPriceStrings() {
      var items = JSON.parse(JSON.stringify(this.treeItems));
      // get items that is not free only
      items = items.filter((i) => !i.isFree);
      // calculate
      var totalPrice = items
        .map((i) => i.priceValue || 0)
        .reduce((a, b) => a + b, 0);
      var finalPrice = items
        .map((i) => i.discountedValue || 0)
        .reduce((a, b) => a + b, 0);
      var discountedPrice = totalPrice - finalPrice;
      this.totalPriceString = convertPriceString(totalPrice) || "0 VND";
      this.totalDiscountPriceString =
        convertPriceString(discountedPrice) || "0 VND";
      this.finalPriceString = convertPriceString(finalPrice) || "0 VND";
    },
    async removeItemFromTreeViews(items) {
      // Get first item from list
      var item = items && items[0];
      if (!item) return;
      // find parent serviceID
      var isParent = item.isParent;
      // if it is a parent item
      if (isParent) {
        // then we just remove it from treeview
        this.treeItems = this.treeItems.filter((i) => i.id != item.id);
        // then finish the whole function
        return;
      }
      // if it has serviceID => it is not parent item
      // find the parentItem
      var parentItem = this.treeItems.find(
        (i) => i.children && i.children.find((c) => c.id == item.id)
      );
      if (!parentItem) return;
      // check if we are removing all of its children
      if (parentItem.children && parentItem.children.length == items.length) {
        // then we just remove the parent item from treeview
        this.treeItems = this.treeItems.filter((i) => i.id != parentItem.id);
        // then finish the whole function
        return;
      }
      // if not then we are only removing one item from the parent item
      // find the remainging items
      var remainItems = parentItem.children.filter((c) => c.id != item.id);
      // remove the whole parent item as a group
      this.treeItems = this.treeItems.filter((i) => i.id != parentItem.id);
      // then foreach remain items
      // foreach serviceItem
      for (var serviceItem of remainItems) {
        // add serviceItem into tree view as global item
        await this.addIntoTreeView(serviceItem.itemID, serviceItem.type);
      }
    },
    // Function that handle removed items from selections
    async handleRemovedOrderItems(items) {
      console.log(items);
      await this.removeItemFromTreeViews(items);
      this.displayPriceStrings();
      this.renderListFilteredPromotions();
      this.$emit("onTreeItemsChanged", this.treeItems);
    },
    async getListClinicRooms() {
      const result = await BrandService.searchClinicRooms("", 1, 100);
      if (result.error) {
        this.showError("Can not get list rooms. Please try again later.");
        return;
      }
      this.rooms = result.items;
    },
    renderListFilteredPromotions() {
      // init default list
      this.filteredPromotions = [];
      var promotions = [];
      this.treeItems.forEach((selection) => {
        selection.promotionIDs.forEach((promotionID) => {
          if (!promotions.find((promotion) => promotion.value == promotionID)) {
            var promotion = this.promotions.find(
              (promotion) => promotion.value == promotionID
            );
            if (promotion) {
              promotions.push(promotion);
            }
          }
        });
      });
      this.filteredPromotions = promotions;
      this.filteredPromotions.unshift({
        value: null,
        text: "No promotion",
        isPercentage: false,
        amount: 0,
      });
    },
  },
};
</script>
<style lang="scss">
.v-application .success--text {
  color: #4caf50 !important;
  caret-color: #4caf50 !important;
}
.service-information {
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .h-4 {
    height: 40px !important;
  }
  .lh-4 {
    line-height: 40px !important;
  }
  .row-title {
    width: 90px;
    display: inline-block;
    text-align: right;
  }
  .row-text {
    display: inline-block;
    text-align: left;
  }
  .fw {
    width: 100%;
    float: left;
  }
  .tc {
    text-align: center;
  }
  .tr {
    text-align: right;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px 0px;
  }
  .v-treeview-node__root {
    padding-right: 0px;
  }
  .success {
    color: yellow;
    background-color: #4caf50 !important;
  }
  .bold {
    text-transform: uppercase;
  }
  .container {
    max-width: 1360px !important;
  }
  .no-data-text {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding: 5px;
  }
}
</style>