<template>
  <v-dialog v-model="popupDialog" width="1360px" eager>
    <v-card>
      <v-container class="pa-0 ma-0 admission">
        <v-container class="pa-0 ma-0 pl-3 pr-3 service-section">
          <ServiceInformation
            ref="ServiceInfoCard"
            @onError="showError"
            @onTreeItemsChanged="handleTreeItemsChanged"
          />
        </v-container>
        <v-card-actions class="justify-center pb-5">
          <v-btn text @click="popupDialog = false">{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn
            :loading="isLoading"
            v-show="isAllowSaveData"
            text
            @click="saveNewAxPxs"
            class="primary pa-4 ml-10 pl-10 pr-10"
            >{{ $t("common.Save") }}
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <DialogConfirm ref="DialogConfirm" :message="confirmMessage" />
  </v-dialog>
</template>

<script>
import AdmissionService from "@/services/admission";
import ServiceInformation from "./ServiceInformation";
import DialogConfirm from "@/components/DialogConfirm";
import i18n from "@/plugins/i18n";
export default {
  props: {},
  components: {
    ServiceInformation,
    DialogConfirm,
  },
  data: () => ({
    popupDialog: false,
    isLoading: false,
    isAllowSaveData: false,
    confirmMessage: "",
  }),
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    async openPopup() {
      this.popupDialog = true;
      this.$refs.ServiceInfoCard.resetForm();
    },
    handleTreeItemsChanged(treeItems) {
      if (!treeItems || !treeItems.length) {
        this.isAllowSaveData = false;
        return;
      }
      this.isAllowSaveData = true;
    },
    async saveNewAxPxs() {
      this.isLoading = true;
      let promotionID = this.$refs.ServiceInfoCard.promotionID || null;
      let treeItems = this.$refs.ServiceInfoCard.treeItems || [];
      let orderItems = [];
      treeItems.forEach((i) => {
        // add parent item => parentServiceID = null
        orderItems.push({
          itemName: i.name,
          isFree: i.isFree || false,
          targetID: i.itemID,
          typeID: i.type,
          staffPerformID: i.doctorID || null,
          roomID: i.roomID || null,
          parentServiceID: null,
          promotionID: i.promotionIDs.find((id) => id == promotionID) || null,
        });
        // add children item => parentServiceID = serviceID
        // but promotionID = null because price is included
        if (i.children && i.children.length) {
          i.children.forEach((child) => {
            orderItems.push({
              itemName: child.name,
              isFree: child.isFree || false,
              targetID: child.itemID,
              typeID: child.type,
              staffPerformID: child.doctorID || null,
              roomID: child.roomID || null,
              parentServiceID: child.serviceID || null,
              promotionID: null,
            });
          });
        }
      });
      this.confirmMessage = `
        Are you sure to add these new <br/> Assetments / Procedures?`;
      // <ul><li>${orderItems
      //   .map((i) => i.itemName)
      //   .join("</li><li>")}</li></ul>`;
      if (!(await this.$refs.DialogConfirm.confirm())) {
        this.isLoading = false;
        return;
      }
      var episodeRecordID = this.$route.params.episodeRecordID;
      if (!episodeRecordID) return;
      const result = await AdmissionService.addNewOrderItemsIntoEpisodeRecord(
        episodeRecordID,
        orderItems
      );
      this.isLoading = false;
      if (result.error) {
        this.showError("Can not add new order items. Please try again later.");
        return;
      }
      this.$emit("onAddNewOrderItemsSuccess", result);
      this.showSuccess(i18n.t("message.axPxPopUp"));
      this.popupDialog = false;
    },
  },
};
</script>

<style scoped>
.text-space {
  letter-spacing: 0.5px;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.admission.container {
  max-width: 1360px !important;
}
.admission .container {
  max-width: 1360px !important;
}
</style>
