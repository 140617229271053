<template>
  <v-navigation-drawer
    app
    class="nav-drawer primary d-print-none quick-access-drawer"
    width="58"
    permanent
    right
  >
    <v-list dense nav>
      <v-list-item class="mb-8" v-if="isAllowAddNewAxPx">
        <v-list-item-icon>
          <v-icon size="27" @click="openOrderService">
            mdi-clipboard-text
          </v-icon>
          <span class="icon-label" @click="openOrderService">
            {{ $t("quickAccess.orderService") }}
          </span>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item class="mb-8">
        <v-list-item-icon>
          <v-icon size="27" @click="openClinicalNote"> mdi-pencil </v-icon>
          <span class="icon-label" @click="openClinicalNote">
            {{ $t("quickAccess.clinicalNote") }}
          </span>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item class="mb-8">
        <v-list-item-icon>
          <v-icon size="27" @click="openCalendar"> mdi-calendar </v-icon>
          <span class="icon-label" @click="openCalendar">
            {{ $t("quickAccess.doctorCalendar") }}
          </span>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon size="27"> mdi-plus </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <ClinicalNotePopup ref="ClinicalNotePopup" />
    <AddAxPxPopup
      ref="AddAxPxPopup"
      @onAddNewOrderItemsSuccess="handleAddNewOrderItemsSuccess"
    />
  </v-navigation-drawer>
</template>

<script>
import ClinicalNotePopup from "@/components/Dashboard/Form/ClinicalNotePopup";
import AddAxPxPopup from "@/components/common/EpisodeRecord/AddAxPxPopup";
import EpisodeService from "@/services/episode";

export default {
  components: {
    ClinicalNotePopup,
    AddAxPxPopup,
  },
  data() {
    return {
      mini: true,
      isAllowAddNewAxPx: true,
      episode: {},
    };
  },
  computed: {
    userTypeID() {
      return this.$store.getters.userData.userTypeID;
    },
  },
  created() {
    this.getEpisodeInfo();
    this.sockets.common.on("EpisodeCompleted", this.onEpisodeCompleted);
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    openClinicalNote() {
      this.$refs.ClinicalNotePopup.openCreate();
    },
    openCalendar() {
      window.open("/home/calendar");
    },
    openOrderService() {
      this.$refs.AddAxPxPopup.openPopup();
    },
    handleAddNewOrderItemsSuccess(val) {
      this.$emit("onAddNewOrderItemsSuccess", val);
    },
    onEpisodeCompleted(episodeID) {
      if (this.episode.id == episodeID) {
        this.isAllowAddNewAxPx = false;
      }
    },
    async getEpisodeInfo() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      const result = await EpisodeService.getEpisodeDetailByEpisodeRecord(
        episodeRecordID
      );
      if (!result || result.error) {
        this.showError(
          "Can not get episode information. Please try again later."
        );
        return;
      }
      if (result.statusID == 2) {
        this.isAllowAddNewAxPx = false;
      }
      this.episode = result;
    },
  },
};
</script>
<style lang="scss">
.quick-access-drawer {
  .v-navigation-drawer__content {
    padding-top: 0px !important;
    .v-list-item.theme--light:hover {
      color: white !important;
    }
    .v-list-item.theme--light {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.7) !important;
      .v-icon {
        color: inherit;
        margin-left: 1px;
      }
      .icon-label {
        font-size: 10px;
        width: 56px;
        display: inline-block;
        position: absolute;
        top: 34px;
        text-align: center;
        left: -8px;
      }
    }
  }
}
</style>
