<template>
  <v-main class="full-layout ma-0 greyBg">
    <v-navigation-drawer app class="greyBg d-print-none">
      <v-list dense shaped>
        <v-list-item to="chief-complaints" link active-class="active-class">
          <v-list-item-title>{{
            $t("examination.chiefComplain")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="general-check-up" link active-class="active-class">
          <v-list-item-title>{{
            $t("examination.generalCheckUp.title")
          }}</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item to="refractiono" link active-class="active-class">
          <v-list-item-title>{{
            $t("examination.refactornoAndVSA")
          }}</v-list-item-title>
        </v-list-item> -->
        <v-list-item to="visualAcuityTono" link active-class="active-class">
          <v-list-item-title>{{
            $t("examination.refactornoAndVSA")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <Navigator
        :title="$t('examination.optometrist')"
        :navigator="optometristNavigator"
      />
      <Navigator
        :title="$t('examination.doctor')"
        :navigator="doctorNavigator"
      />
      <v-list dense shaped class="ma-0 pa-0 mb-10">
        <v-list-item to="follow-up" link active-class="active-class">
          <v-list-item-title>{{
            $t("examination.followUp.title")
          }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="discharge-summary" link active-class="active-class">
          <v-list-item-title>{{
            $t("examination.dischargeSummary.title")
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="exam-sheet pa-10 pt-6 fixed-block">
      <h2 class="mb-2 text-capitalize d-print-none">
        {{ convertLangTitle() }}
      </h2>
      <router-view @onOrderCompleted="handleOrderCompleted" class="view" />
    </div>
    <QuickAccess @onAddNewOrderItemsSuccess="handleAddNewOrderItemsSuccess" />
  </v-main>
</template>

<script>
import { UserTypeCodes } from "@/plugins/constant";
import WorkListService from "@/services/work-list";
import QuickAccess from "./components/QuickAccess";
import Navigator from "./components/Navigator";
import i18n from "@/plugins/i18n";

const optometristNavigator = {
  examinations: [],
  navigations: [
    {
      name: i18n.t("examination.treatmentPlan.title"),
      path: `optometry-treatment`,
    },
    {
      name: i18n.t("examination.conclusion.title"),
      path: `optometrist-conclusion`,
    },
  ],
};
const doctorNavigator = {
  examinations: [],
  navigations: [
    {
      name: i18n.t("examination.diagnosis.title"),
      path: `diagnosis`,
    },
    {
      name: i18n.t("examination.treatmentPlan.title"),
      path: `doctor-treatment-plan`,
    },
  ],
};
export default {
  components: {
    QuickAccess,
    Navigator,
  },
  data() {
    return {
      mini: true,
      optometristNavigator,
      doctorNavigator,
    };
  },
  computed: {
    userTypeID() {
      return this.$store.getters.userData.userTypeID;
    },
    isAllowAddNewAxPx() {
      if (this.$route.params.episodeRecordID) return true;
      return false;
    },
  },
  created() {
    this.renderListServices();
    window.addEventListener("onCancelOrderItemSuccess", () => {
      this.renderListServices();
      this.$router.push("general-check-up");
    });
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    handleOrderCompleted(orderID) {
      // update the status into queue list
      // first, find the index of the order success
      var ind = this.optometristNavigator.examinations.findIndex(
        (o) => o.orderID == orderID
      );
      // emit statusID = 3 to parient
      // update this order with status = 3
      if (ind > -1) {
        this.optometristNavigator.examinations[ind].statusID = 3;
        return;
      }
      ind = this.doctorNavigator.examinations.findIndex(
        (o) => o.orderID == orderID
      );
      // emit statusID = 3 to parient
      // update this order with status = 3
      if (ind > -1) {
        this.doctorNavigator.examinations[ind].statusID = 3;
        return;
      }
    },
    // function to render list service items queue
    async renderListServices() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      if (!episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(episodeRecordID);
      if (result.error) {
        this.showError("Cannot get work list info! Please try again later!");
      }
      var { orders } = result || {};
      var items = JSON.parse(JSON.stringify(orders || []));
      // map path for each order
      items.forEach((item) => {
        item.path = `examination?orderID=${item.orderID}&formID=${item.formID}&targetID=${item.targetID}`;
        item.permissionUserTypeIDs = item.permissionUserTypeIDs || [];
      });
      // find order performed by optomologist
      this.optometristNavigator.examinations = items.filter((i) =>
        i.permissionUserTypeIDs.includes(UserTypeCodes.Optomologist)
      );
      // find other orders
      this.doctorNavigator.examinations = items.filter(
        (i) => !i.permissionUserTypeIDs.includes(UserTypeCodes.Optomologist)
      );
    },
    findNameByPath() {
      var paths = this.$route.path.split("/");
      return paths.pop().split("-").join(" ");
    },
    convertLangTitle() {
      var text = this.findNameByPath();
      console.log(text);
      var val = "";
      switch (text) {
        case "chief complaints":
          val = i18n.t("examination.chiefComplain");
          break;
        case "general check up":
          val = i18n.t("examination.generalCheckUp.title");
          break;
        case "examination":
          val = i18n.t("examination.title");
          break;
        case "optometry treatment":
          val = i18n.t("examination.optometryTreatment.title");
          break;
        case "optometrist conclusion":
          val = i18n.t("examination.optometristConclusion.title");
          break;
        case "diagnosis":
          val = i18n.t("examination.diagnosis.title");
          break;
        case "doctor treatment plan":
          val = i18n.t("examination.doctorTreatmentPlan.title");
          break;
        case "follow up":
          val = i18n.t("examination.followUp.title");
          break;
        case "discharge summary":
          val = i18n.t("examination.dischargeSummary.title");
      }
      console.log(val);
      return val;
    },
    handleAddNewOrderItemsSuccess() {
      this.renderListServices();
    },
  },
};
</script>
<style scoped lang="scss">
.active-class {
  background: white;
}
.exam-sheet {
  width: calc(100% - 16px);
  min-height: calc(100vh - 32px);
  margin: 17px 9px 8px 0px;
  background: white;
  height: 60vh;
  display: inline-block;
}
</style>
<style lang="scss">
.v-navigation-drawer__content {
  padding-top: 25px !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 8px;
  margin-left: -2px;
}
.fixed-block {
  overflow-y: auto;
  max-height: 1200px;
}
</style>
